const { UNDEFINED_IN_URL_ERROR } = require('../enums/errors');

const getSentry = () => import('@sentry/browser');

function sentryCaptureException (error) {
  getSentry().then(sentryModule => {
    sentryModule.captureException(error);
  });
}

class UndefinedInUrlError extends Error {
  constructor (message) {
    super(message);
    this.name = UNDEFINED_IN_URL_ERROR;
  }
}

function sendUndefinedInUrlErrorMessage (Sentry, error, destinationUrl, referer = '') {
  Sentry.withScope(scope => {
    scope.setLevel('warning');
    scope.setTag('destinationUrl', destinationUrl);
    if (referer) {
      scope.setTag('referer', referer);
    }
    Sentry.captureException(error);
  });
}

function catchUndefinedInUrlError (destinationUrl, referer = '') {
  getSentry().then(sentryModule => {
    sendUndefinedInUrlErrorMessage(
      sentryModule,
      new UndefinedInUrlError('The "undefined" part was found in the URL'),
      destinationUrl,
      referer,
    );
  });
}

module.exports = {
  catchUndefinedInUrlError,
  sentryCaptureException,
};
